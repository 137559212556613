@font-face {
    font-family: 'Avenir';
    src: url('./assets/fonts/Avenir-Book.eot');
    src: url('./assets/fonts/Avenir-Book.eot?#iefix') format('embedded-opentype'),
        url('./assets/fonts/Avenir-Book.woff2') format('woff2'),
        url('./assets/fonts/Avenir-Book.woff') format('woff'),
        url('./assets/fonts/Avenir-Book.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Avenir';
    src: url('./assets/fonts/Avenir-Medium.eot');
    src: url('./assets/fonts/Avenir-Medium.eot?#iefix') format('embedded-opentype'),
        url('./assets/fonts/Avenir-Medium.woff2') format('woff2'),
        url('./assets/fonts/Avenir-Medium.woff') format('woff'),
        url('./assets/fonts/Avenir-Medium.ttf') format('truetype');
    font-weight: normals;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Avenir';
    src: url('./assets/fonts/Avenir-Heavy.eot');
    src: url('./assets/fonts/Avenir-Heavy.eot?#iefix') format('embedded-opentype'),
        url('./assets/fonts/Avenir-Heavy.woff2') format('woff2'),
        url('./assets/fonts/Avenir-Heavy.woff') format('woff'),
        url('./assets/fonts/Avenir-Heavy.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}



body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif; */
  font-family: 'Avenir', 'Helvetica Neue', sans-serif;
  font-weight: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}
