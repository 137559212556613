@keyframes ripple {
  0% {
    transform: scale(0.8);
    opacity: 1;
  }

  100% {
    transform: scale(2.4);
    opacity: 0;
  }
}

@font-face {
  font-family: 'Avenir Next';
  src: url('./assets/fonts/AvenirNext-DemiBold.woff2') format('woff2'),
    url('./assets/fonts/AvenirNext-DemiBold.woff') format('woff'),
    url('./assets/fonts/AvenirNext-DemiBold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Avenir Next';
  src: url('./assets/fonts/AvenirNext-Regular.woff2') format('woff2'),
    url('./assets/fonts/AvenirNext-Regular.woff') format('woff'),
    url('./assets/fonts/AvenirNext-Regular.ttf') format('truetype');
  font-weight: normals;
  font-style: normal;
  font-display: swap;
}